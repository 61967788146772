
import DisplayViewUtils from "./DisplayViewUtils.js";
//import StringUtils  from '@/utils/StringUtils.js';
//import ConstUtils  from '@/utils/ConstUtils.js';
//
//import Checklist from '@/domain/model/checklist/CheckList.js';
//import ChecklistItem from '@/domain/model/checklist/item/CheckListItem.js';
//import ChecklistItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';
//import ChecklistItemOptionMap from '@/domain/model/checklist/item/option/CheckListItemOptionMap.js';

export default class DisplayViewModel {
  
  constructor(panel, inspection) {
    this._panel = panel;
    this._inspection = inspection;
    this._tableData = [];
    this._domain = this.panel().domain;
    this._isSaving = false;
    this._showMissingRequirements = false;
    this._error = null;
  }
  
  start() {
    this.populateData();
  }
  
  populateData() {
    this._tableData = [];
    var newData = []
    this.buildRowData(newData)
    //this.domain().logger().info("Populate Data - Inspection: {0}", [this.inspection().toString()]);
    this._tableData = newData;
    return this;
  }
  
  buildRowData(newData) {
    var au = new DisplayViewUtils(this.domain(), this.panel(), newData, this._state);
    au.start();
  }
  
  domain() {
    return this._domain;
  }
  
  panel() {
    return this._panel;
  }
  
  inspection() {
    return this._inspection;
  }
  
  withValue(kvp) {
    this._showMissingRequirements = false;
    if (kvp) {
      this.domain().logger().info("Received: {0}", [JSON.stringify(kvp)]);
    }
  }
  
  withButtonPressed(buttonData) {
    if (!buttonData) {
      return;
    }
    this.domain().logger().info("Button: {0}", [JSON.stringify(buttonData)]);
    var field = buttonData['field'];
    if (field === "refresh") {
      this.panel().refresh();
    }
    if (field === "rebuild") {
      this.panel().rebuild();
    }
    if (field === "print") {
      this.panel().print();
    }
    if (field === "emailToMto") {
      this.panel().email();
    }
    if (field === "remarks") {
      this.panel().remarks();
    }
    if (field === "repair") {
      this.panel().repair();
    }
  }
  
  tableData() {
    return this._tableData;
  }
  
  isSaving() {
    return this._isSaving;
  }
  
  areRequirementsMet() {
    var met = true;
    return met;
  }
  
}







